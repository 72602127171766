<template>
  <div class="w-full h-screen flex items-center justify-center">
    <h2 class="text-black">Page Not Found</h2>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
